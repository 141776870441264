import React,{useState} from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Axios from 'axios';
import Qs from 'querystring'
import ModalBox from '../pages/modalbox';
import Header from "./header"
import Footer from './footer';
import "./layout.css"

import {API_URL,CONFIG_WITHOUT_TOKEN} from '../appconstant/index';

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  const [show, setShow] = useState(false);
  const [smessage, setSmessage] = useState("");
  const [emessage, setEmessage] = useState("");
  const [emaildata, setEmaildata] = useState("");


  const openModal = () => {
      setShow(true);
      setSmessage("");
      setEmessage("");
      setEmaildata("");
  }
  const closeModal = () => {
    setShow(false);
  }
  const inputChange = (e) => {
    setEmaildata(e.target.value)
  }
  const formSubmit = (e) =>{
      e.preventDefault();
      let submit_email_value = e.target.email.value;
      let email_data = {};
      email_data.email = submit_email_value;

      Axios.post(API_URL + '/apis/waitlist',Qs.stringify(email_data),CONFIG_WITHOUT_TOKEN)
      .then((response) =>  {
          if(response.data.error == 0){
              setSmessage(response.data.message);
              setEmaildata("");
              setEmessage("");
              setTimeout(() => {
                  setShow(false);
              }, 2000 )
          }else if(response.data.error == 1){
            setEmessage(response.data.message);
            setSmessage("");

          }
      })
      .catch((error) =>  {
        setEmessage(error.message);
        setSmessage("");
      });
  }

  return (
    <>
      <Header openModal={openModal}  siteTitle={data.site.siteMetadata?.title || `Title`} />
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 1200,
          padding: `0 1.0875rem 1.45rem`,
        }}
      >
        <main>{children}</main>
      </div>
        <ModalBox
        show={show}
        handleClose={closeModal}
        smessage={smessage}
        emessage={emessage}
        email={emaildata}
        oninputchange={inputChange}
        onsubmit={formSubmit}
          />
      <Footer/>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
